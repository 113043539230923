/*
 * @Author: sqk
 * @Date: 2021-03-19 16:47:35
 * @LastEditTime: 2022-02-28 17:03:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\privacy\index.js
 */
export default {
  components: {},
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/",
          name: "",
          title: "物流协议"
        }
      ],
      isMobile:false,
      fxcolumns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 100
        },
        {
          title: "类型",
          dataIndex: "type",
          width: 200
        },
        {
          title: "场景",
          dataIndex: "scene",
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          title: "收费规则",
          dataIndex: "feeRules",
          scopedSlots: {
            customRender: 'feeRules'
          }
        },
      ],
      fxData:[
        {
          num:'1',
          type:'拦截/拒收',
          scene:'①出库后用户/商家下发拦截，或用户拒收 ②无法联系到用户 ③地址超派 ④地址信息错误等用户/商家原因产生的退货由商家承担正逆向物流费用',
          feeRules:'运抵后拒收的，按B2C全程收费标准的*1.5倍，具体收费金额以账单为准。'
        },
        {
          num:'2',
          type:'暂存费',
          scene:'出库后超15天无法收货的，由商家承担暂存费用',
          feeRules:'发货后超15天无法收货的收取暂存费，收费标准5元/方/天，最低标准2.5元/票/天。具体收费金额以账单为准。'
        },
        {
          num:'3',
          type:'商家/用户原因退货',
          scene:'因商家/用户原因退货产生的正逆向运费、商品鉴定、更换包装箱、商品维修等费用',
          feeRules:'具体收费金额以账单为准。'
        },
        {
          num:'1',
          type:'不再享受免运费政策',
          scene:'含免运费产品的订单，因商家/用户原因拒收免运费产品的',
          feeRules:'不再免除相关运费，具体收费金额以账单为准。'
        }
      ]
    };
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {
      $('#privacy-box').css("font-size",  (document.documentElement.clientWidth / 1920)*100 + 'px' );
      const sWidth = document.documentElement.clientWidth;
      if(sWidth < 768){
        this.isMobile = true
      }else{
        this.isMobile = false;
      }
    });

  },
  methods: {
    clickNum(id) {
      document.querySelector('#'+id).scrollIntoView(true)

    },
    goBack(){
      this.$router.go(-1)
    }
  },
};
